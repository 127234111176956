import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: HS Hold/Walk/Turn`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`500M Row`}</p>
    <p>{`5 Wall Walks`}</p>
    <p>{`15 T2B`}</p>
    <p>{`25 Squats`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Scores for 18.4 must be submitted by 8:00pm tonight!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      